import PDFPreviewDialog from "@/common/PDFPreviewDialog"
import TabsNav from "@/components/blocks/UserTabs"
import { useGetPDFUrl } from "@/hooks/usePDFGeneration"
import BRTThemeProvider from "@/providers/theme"
import { Box, Button, Card, Stack, Typography } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useRef, useState } from "react"
import CSVUpload from "./CSVUpload"
import useDialogState from "./hooks/useDialogState"
import StudentsTable from "./StudentsTable"

const StudentsPage = ({ currentPath, currentUser }) => {
  const apiRef = useGridApiRef()
  const printRef = useRef()
  const [studentsToPrint, setStudentsToPrint] = useState([])
  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const { generatePDFUrl } = useGetPDFUrl(openPreviewDialog)

  const handleRowSelectionModelChange = useCallback(newSelectionModel => {
    setRowSelectionModel(newSelectionModel)
  }, [])

  const handleGeneratePDF = useCallback(() => {
    const selectedStudents = new Array()
    if (studentsToPrint.length > 0) {
      selectedStudents.push(...studentsToPrint)
    } else if (rowSelectionModel.length > 0) {
      selectedStudents.push(...rowSelectionModel)
    }
    generatePDFUrl(selectedStudents)
  }, [rowSelectionModel, studentsToPrint, generatePDFUrl])

  const isPrintButtonDisabled = rowSelectionModel.length === 0
  return (
    <BRTThemeProvider>
      <Box className="container panel-wrapper" sx={{ pb: 4 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Typography variant="h2" component="h1" letterSpacing={-0.8} sx={{ color: "#1f007a" }}>
            Students
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-end">
            <CSVUpload />
            <Button href="/admin/sessions/new" variant="outlined" color="primary">
              Schedule Family Session
            </Button>
            <Button href="/admin/students/new" variant="outlined" color="primary">
              New Student
            </Button>
            <Button variant="outlined" color="primary" onClick={handleGeneratePDF} disabled={isPrintButtonDisabled}>
              Print Credentials
            </Button>
          </Stack>
        </Stack>
        <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <TabsNav currentUser={currentUser} currentPath={currentPath} />
          <StudentsTable
            apiRef={apiRef}
            setStudentsToPrint={setStudentsToPrint}
            rowSelectionModel={rowSelectionModel}
            handleRowSelectionModelChange={handleRowSelectionModelChange}
          />
        </Card>
        <PDFPreviewDialog
          open={previewDialogState.open}
          onClose={closePreviewDialog}
          pdfUrl={previewDialogState.pdfUrl}
          fileName={`admin_student_credentials_${new Date().toISOString()}.pdf`}
          title="Student Credentials PDF"
        />
      </Box>
      <Box ref={printRef} />
    </BRTThemeProvider>
  )
}

export default StudentsPage