import { STUDENT_LOGIN_PDF_URL } from "@/constants"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { useCallback, useState } from "react"
import { renderToString } from "react-dom/server"

/**
 * Custom hook for generating PDFs from a list of items.
 *
 * @param {Function} openPreviewDialog - Function to open the preview dialog with the generated PDF URL.
 * @param {Object} [options={}] - Options for PDF generation.
 * @param {number} [options.itemsPerPage=8] - Number of items to display per page.
 * @param {string} [options.pageSize="a4"] - Page size for the PDF.
 * @param {string} [options.orientation="portrait"] - Orientation of the PDF pages.
 * @param {Function} options.renderItem - Function to render each item.
 * @param {Object} [options.renderItemProps={}] - Additional props to pass to the renderItem function.
 * @param {Object} [options.margins={ top: 10, right: 10, bottom: 10, left: 10 }] - Margins for the PDF pages in mm.
 *
 * @returns {Object} - Returns an object containing the loading state, error state, and generatePDF function.
 * @returns {boolean} isLoading - Indicates if the PDF generation is in progress.
 * @returns {Error|null} error - Error object if an error occurred during PDF generation.
 * @returns {Function} generatePDF - Function to generate the PDF from a list of items.
 */
export const usePDFGeneration = (openPreviewDialog, options = {}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const {
    itemsPerPage = 8,
    pageSize = "a4",
    orientation = "portrait",
    renderItem,
    renderItemProps = {},
    margins = { top: 10, right: 10, bottom: 10, left: 10 }, // mm
  } = options

  const generatePDF = useCallback(
    async items => {
      setIsLoading(true)
      setError(null)

      try {
        const pdf = new jsPDF(orientation, "mm", pageSize)
        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight()

        const contentWidth = pageWidth - margins.left - margins.right
        const contentHeight = pageHeight - margins.top - margins.bottom
        const columns = Math.floor(Math.sqrt(itemsPerPage))
        const rows = Math.ceil(itemsPerPage / columns)
        const itemWidth = contentWidth / columns
        const itemHeight = contentHeight / rows

        // Create a hidden container for PDF generation
        const hiddenContainer = document.createElement("div")
        hiddenContainer.style.position = "absolute"
        hiddenContainer.style.left = "-9999px"
        hiddenContainer.style.top = "-9999px"
        document.body.appendChild(hiddenContainer)
        for (let i = 0; i < items.length; i += itemsPerPage) {
          if (i > 0) pdf.addPage()

          const pageItems = items.slice(i, i + itemsPerPage)
          const pageContainer = document.createElement("div")
          pageContainer.style.width = `${contentWidth}mm`
          pageContainer.style.height = `${contentHeight}mm`
          pageContainer.style.display = "flex"
          pageContainer.style.flexWrap = "wrap"
          pageContainer.style.justifyContent = "center"
          pageContainer.style.alignContent = "center"
          pageContainer.style.margin = `${margins.top}mm ${margins.right}mm ${margins.bottom}mm ${margins.left}mm`

          pageItems.forEach(item => {
            const itemContainer = document.createElement("div")
            itemContainer.style.width = `${itemWidth}mm`
            itemContainer.style.height = `${itemHeight}mm`
            itemContainer.style.boxSizing = "border-box"
            itemContainer.style.display = "flex"
            itemContainer.style.justifyContent = "center"
            itemContainer.style.alignItems = "center"

            const itemContent = renderToString(renderItem({ item, ...renderItemProps }))
            itemContainer.innerHTML = itemContent
            pageContainer.appendChild(itemContainer)
          })

          hiddenContainer.appendChild(pageContainer)
          const canvas = await html2canvas(pageContainer, { scale: 2 })
          hiddenContainer.removeChild(pageContainer)

          const imgData = canvas.toDataURL("image/png")
          pdf.addImage(imgData, "PNG", margins.left, margins.top, contentWidth, contentHeight)
        }

        // Remove the hidden container
        document.body.removeChild(hiddenContainer)
        const pdfUrl = pdf.output("bloburl")
        openPreviewDialog(pdfUrl)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [itemsPerPage, pageSize, orientation, renderItem, renderItemProps, openPreviewDialog, margins]
  )

  return { isLoading, error, generatePDF }
}

const createPDFRequest = ids => {
  if (!Array.isArray(ids)) {
    return ""
  }

  const url = new URL(STUDENT_LOGIN_PDF_URL, window.location.origin)

  ids.forEach(id => {
    url.searchParams.append("ids[]", id)
  })

  return url.toString()
}

/**
 * Custom hook to generate a PDF URL and open a preview dialog.
 *
 * @param {Function} openPreviewDialog - Function to open the preview dialog with the generated PDF URL.
 * @returns {Object} - An object containing the generatePDFUrl function.
 */
export const useGetPDFUrl = openPreviewDialog => {
  const getSelectedStudentIds = (selectedStudents = []) => {
    return selectedStudents.map(student => student.identifier)
  }

  const generatePDFUrl = useCallback(
    selectedRows => {
      const ids = typeof selectedRows[0] === "number" ? selectedRows : getSelectedStudentIds(selectedRows)
      const pdfRequestUrl = createPDFRequest(ids)
      openPreviewDialog(pdfRequestUrl)
    },
    [openPreviewDialog]
  )

  return { generatePDFUrl }
}
